@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Agdasima:wght@400;700&display=swap');

* {
    box-sizing: border-box;
}

html {
    font-family: 'Agdasima', sans-serif;
}